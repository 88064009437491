// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAIMtyP9YGjUjH8DzTB2F2s7avC450lQoA",
    authDomain: "ufc-live-chat.firebaseapp.com",
    projectId: "ufc-live-chat",
    storageBucket: "ufc-live-chat.appspot.com",
    messagingSenderId: "1086137186876",
    appId: "1:1086137186876:web:0c5b563a29597788dca139",
    measurementId: "G-G99WFK6D73"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);