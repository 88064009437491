import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import ChatBox from './PageOne';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [isPageOneVisible, setIsPageOneVisible] = useState(false);
  const [showPageOneButton, setShowPageOneButton] = useState(true);
  const [username, setUsername] = useState('');

  const ACCENT_COLOR = "#D20A0A";
  const BACKGROUND_COLOR = "#000000";
  const PRIMARY_COLOR = "#222222";
  const TITLE_COLOR =  "#FFFFFF";

  const handleShowPageOne = () => {
    setShowPageOneButton(false);

    // Add a delay before setting isPageOneVisible to trigger the fade-out effect
    setTimeout(() => {
      setIsPageOneVisible(true);
    }, 500); // Adjust the delay as needed
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleSaveUsername = () => {
    const profanityList = require('./profanityList.json');
    const usernameWithoutSpaces = username.replace(/\s/g, '');
    const containsProfanity = profanityList.some((word) => {
      const wordWithoutSpaces = word.replace(/\s/g, '');
      return usernameWithoutSpaces.toLowerCase().includes(wordWithoutSpaces.toLowerCase());
    });

    console.log(username);
    console.log(username.trim());
    if (containsProfanity) {
      toast.error('Inappropriate username! Please choose another.');
      setUsername('');
    } else if (username.trim() === '') {
      toast.error('Blank username! Please enter username.');
      setUsername('');
    } else {
      toast.success(`Username "${username}" saved successfully!`);
      // Set a cookie with the username
      document.cookie = `username=${username}; max-age=${60 * 60 * 24 * 7}`; // 1 week expiration
      handleShowPageOne();
    }
  };

  useEffect(() => {
    // Check for existing username cookie on component mount
    const usernameCookie = document.cookie.split('; ').find(row => row.startsWith('username='));
    if (usernameCookie) {
      const existingUsername = usernameCookie.split('=')[1];
      setUsername(existingUsername);
      handleShowPageOne();
    }
  }, []);

  return (
    <Container maxWidth={false} sx={{ textAlign: 'center', backgroundColor: BACKGROUND_COLOR, height: '100vh' }}>
      <Typography className='bigtitles' variant="h1" color={ACCENT_COLOR} gutterBottom paddingTop={4} margin={0} fontSize='min(10vw, 100px)'>
        UFC Live Chat
      </Typography>
      <Typography className='bigtitles' variant="subtitle1" color={TITLE_COLOR} gutterBottom>
        Discuss UFC events with live updates and more...
      </Typography>
      {showPageOneButton && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            backgroundColor: 'black'
          }}
        >
          <Input
            placeholder="Choose your name"
            value={username}
            onChange={handleUsernameChange}
            disableUnderline
            sx={{ borderBottom: '2px solid', marginTop: '30px', color: ACCENT_COLOR, marginRight: '10px' }}
          />
          <Button
            onClick={handleSaveUsername}
            disabled={username.trim() === '' || username.length > 20}
            sx={{ backgroundColor: ACCENT_COLOR, marginTop: '30px', color: 'white', marginLeft: '10px' }}
          >
            Join
          </Button>
          <ToastContainer/>
        </div>
      )}
      <div
        style={{
          opacity: isPageOneVisible ? 1 : 0,
          transition: 'opacity 1s ease-in-out',
          marginTop: '20px',
        }}
      >
        {isPageOneVisible && (
          <ChatBox
            username={username}
            ACCENT_COLOR={ACCENT_COLOR}
            BACKGROUND_COLOR={BACKGROUND_COLOR}
            PRIMARY_COLOR={PRIMARY_COLOR}
          />
        )}
      </div>
      <ToastContainer />
    </Container>
  );
}

export default App;
