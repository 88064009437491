/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Filter from 'bad-words';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid'; // Import Grid
import { getDatabase, ref, push, onValue } from 'firebase/database';
import { firebaseApp } from './Firebase'; // Adjust the path accordingly
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FighterCard from './FighterCard';
import { fetchStreamLink, updateFighterVote } from './FirebaseApiCalls';
import { createTheme, ThemeProvider, Button } from '@mui/material';

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: "#D20A0A",
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: "#D20A0A",
            },
            '&:active .MuiOutlinedInput-notchedOutline': {
              borderColor: "#D20A0A",
            },
          },
          '& .MuiInputLabel-outlined': {
            color: "#D20A0A", // Text color for label
          },
        },
      },
    },
  },
});

const ChatBox = ({ username, ACCENT_COLOR, PRIMARY_COLOR }) => {
  const [messages, setMessages] = useState([]);
  const chatBoxRef = useRef(null);
  const lastSentTimestampRef = useRef(null);
  const [votes, setVotes] = useState([{ 0: 0, 1: 0 }, { 0: 0, 1: 0 }, { 0: 0, 1: 0 }, { 0: 0, 1: 0 }, { 0: 0, 1: 0 }, { 0: 0, 1: 0 }]);
  const [spams, setSpams] = useState(0);
  const [releasetime, setReleasetime] = useState(Date.now())
  const [selectedFighters, setSelectedFighters] = useState([-1, -1, -1, -1, -1, -1]);
  const [snapShotFighterList, setSnapShotFighterList] = useState([]);
  const [dummyState, setDummyState] = useState(0);
  const [streamLink, setStreamLink] = useState("");

  const database = getDatabase(firebaseApp);
  const messagesRef = ref(database, 'messages');
  const fightersRef = ref(database, 'fighters');
  const fighter1Ref = ref(database, 'fighters/fighter1');
  const fighter2Ref = ref(database, 'fighters/fighter2');

  const maxMessages = 500;
  const rateLimitInterval = 500; // 10 seconds in milliseconds
  const spamMessageThreshhold = 3
  const USER_COLOR = "#FFFFFF";
  const MESSAGE_COLOR = "#9f9f9f";

  // Used for chat filter 'bad-words' pkg
  const filter = new Filter();


  const handleSendMessage = (content) => {

    if (content.length == 0) {
      return;
    }

    if (content.length > 200) {
      toast.warning("Message cannot exceed 200 characters")
      return;
    }

    console.log("Here")
    console.log(content)

     // Use regex to match words and separate them from symbols
    const words = content.match(/\b\w+\b/g) || []; // Match words (alphanumeric sequences)
    const symbols = content.split(/\b\w+\b/); // Split by words to capture surrounding symbols

    // Clean only the words using the filter
    const cleanedWords = words.map((word) => filter.clean(word));

    // Recombine cleaned words with original symbols
    const cleanedContent = symbols.reduce(
      (acc, symbol, i) => acc + symbol + (cleanedWords[i] || ''),
      ''
    );

    content = cleanedContent

    // content.target.value = filter.clean(content.target.value);

    const currentTime = Date.now();

    if ((!lastSentTimestampRef.current || currentTime - lastSentTimestampRef.current >= rateLimitInterval)
      && currentTime > releasetime) {
      const newMessage = {
        fighter: selectedFighters[0],
        user: username,
        content,
      };

      push(messagesRef, newMessage);
      lastSentTimestampRef.current = currentTime;
    } else if (currentTime > releasetime) {
      setSpams(spams + 1);
      if (spams >= spamMessageThreshhold) {
        toast.error("You've been a bad boy, timeout time.", { autoClose: 5000 });
        setReleasetime(Date.now() + 10000);
        setSpams(0);
      } else {
        toast.warning('Slow down bro...');
      }
    }
  };

  useEffect(() => {

    const cookieValue = document.cookie
      .split('; ')
      .find(row => row.startsWith('selectedFighters='));

    if (cookieValue) {
      // If the cookie exists, parse the value into an array of numbers
      const numbers = JSON.parse(cookieValue.split('=')[1]);
      setSelectedFighters(numbers);
    } else {
      // If the cookie doesn't exist, set a new one with 6 numbers set to -1
      const defaultNumbers = Array(6).fill(-1);
      document.cookie = `selectedFighters=${JSON.stringify(defaultNumbers)}; expires=${new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toUTCString()}; path=/`;
    }

    onValue(fightersRef, (snapshot) => {
      var oldVotes = votes;
      snapshot.forEach((outerSnapshot) => {
        const i = outerSnapshot.key;
        for (let j = 0; j < 2; j++) {
          const innerSnapshot = outerSnapshot.child(`${j}`);
          const vote = innerSnapshot.child('votes').val() || 0;
          oldVotes[i][j] = vote
        }
      });
      setVotes(oldVotes);
      // Force update the state for our votes to be recognized 'ShallowTesting'
      setDummyState((prev) => prev + 1);
    });
  }, []);


  const handleVote = (index, fighter, focusedIndex) => {
    if (selectedFighters[index] !== -1 || index !== focusedIndex) {
      return;
    }

    const newNumbers = selectedFighters;
    newNumbers[index] = fighter;
    document.cookie = `selectedFighters=${JSON.stringify(newNumbers)}; expires=${new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toUTCString()}; path=/`;
    setSelectedFighters(newNumbers);
    updateFighterVote(index, fighter);
  };

  const streamHandle = (streamNumber) => {
    fetchStreamLink(streamNumber).then((data) => {
      setStreamLink(data);
    }).catch((error) => {
      console.error('Error fetching stream link: ', error);
    });
  }

  useEffect(() => {
    const fightersCookie = document.cookie.split('; ').find(row => row.startsWith('fighters='));
    if (fightersCookie) {
      const existingFighters = fightersCookie.split('=')[1].split(','); // Split the string into an array
      setSelectedFighters(existingFighters);
    }

    fetchStreamLink(0).then((data) => {
      setStreamLink(data);
    }).catch((error) => {
      console.error('Error fetching stream link: ', error);
    });

    // Listen for changes in the database and update messages
    onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setMessages(Object.values(data).slice(-maxMessages));
      }
    });
  }, []);

  useEffect(() => {
    // Scroll to the bottom of the chat box when new messages are added
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <Grid container spacing={2}>
      {/* Left side: Chat Box */}
      <Grid item xs={8}>
        <div style={{
          maxWidth: '700px', maxHeight: '400px', height: '37vw', display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: 'auto'
        }}>
          <iframe
            title="Embedded Solaris"
            frameBorder={0}
            height="100%"
            width="100%"
            src={streamLink}
            allowFullScreen
            scrolling="no"
            sandbox='allow-same-origin allow-scripts'
          ></iframe>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '10px' }}>
          <Button onClick={() => { streamHandle(0) }} sx={{ color: 'white', fontSize: '10px', backgroundColor: ACCENT_COLOR }}>
            STREAM 1
          </Button>
          <Button onClick={() => { streamHandle(1) }} sx={{ color: 'white', fontSize: '10px', backgroundColor: ACCENT_COLOR }}>
            STREAM 2
          </Button>
        </div>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100% - 50vw)',
            minHeight: '30vh'
          }}
        >
          <Paper
            ref={chatBoxRef}
            sx={{
              flex: '1',
              overflowY: 'auto',
              padding: '16px',
              borderRadius: '8px',
              position: 'relative',
              backgroundColor: PRIMARY_COLOR,
              border: `2px solid ${ACCENT_COLOR}`,
              maxHeight: '35vh',
              '&::-webkit-scrollbar': {
                width: '12px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '8px',
              },
            }}
          >
            {messages.map((message, index) => (
              <Typography
                key={index}
                variant="body1"
                sx={{
                  marginBottom: '8px',
                  fontWeight: 'normal',
                  textAlign: 'left',
                  wordWrap: 'break-word',
                  color: MESSAGE_COLOR,
                  fontSize: '12px',
                }}
              >
                {
                  <span className="messages" style={{ color: ACCENT_COLOR, fontWeight: 'bold' }}>
                    {message.fighter != null && message.fighter != -1 ? '(' + snapShotFighterList[message.fighter] + ') ' : ''}
                  </span>
                }
                <span className="messages" style={{ color: USER_COLOR, fontWeight: 'bold' }}>
                  {message.user}:
                </span>
                <span className="messages" style={{ color: MESSAGE_COLOR }}>
                  {` ${message.content}`}
                </span>
              </Typography>
            ))}
          </Paper>
          <ThemeProvider theme={theme}>
            <TextField
              className='messages'
              variant='outlined'
              placeholder={`Type your message as ${username}...`}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSendMessage(e.target.value);
                  e.target.value = '';
                }
              }}
              sx={{
                padding: '12px',
                marginTop: '8px',
                backgroundColor: PRIMARY_COLOR,
                '& input': {
                  color: USER_COLOR,
                  fontFamily: 'Arial, sans-serif',
                },
                '& label': {
                  color: USER_COLOR,
                },
                borderRadius: '8px',
              }}
            />
          </ThemeProvider>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <FighterCard votes={votes} selectedFighters={selectedFighters} ACCENT_COLOR={ACCENT_COLOR} handleVote={handleVote} setSnapShotFighterList={setSnapShotFighterList} />
      </Grid>
    </Grid>
  );
};

export default ChatBox;
