import { getDatabase, ref, onValue, runTransaction } from 'firebase/database';
import { firebaseApp } from './Firebase'; // Adjust the path accordingly

export const fetchFighterData = () => {
    const database = getDatabase(firebaseApp);
    const fightersRef = ref(database, 'fighters');

    return new Promise((resolve, reject) => {
        onValue(fightersRef, (snapshot) => {
            const fightersData = snapshot.val();
            resolve(fightersData);
        }, {
            onlyOnce: true
        });
    });
};

export const updateFighterVote = (bout, fighter) => {
  const database = getDatabase(firebaseApp);
  const fightersRef = ref(database, `fighters/${bout}/${fighter}`);
  try {
      runTransaction(fightersRef,  (transaction) => {
        console.log(transaction);
        console.log(transaction["votes"], "here");
        if(transaction == null) {
          throw new Error("Fighter does not exist!");
        }
        transaction["votes"]++;
        console.log(transaction["votes"]);
        return transaction;
      });
  } catch (e) {
      console.log("Transaction failed: ", e);
  }
};

export const fetchStreamLink = (streamNumber) => {
  const database = getDatabase(firebaseApp);
  var linkRef;
  console.log(streamNumber);
  if (streamNumber === 1) {
    linkRef = ref(database, 'stream_link1');
  } else {
    linkRef = ref(database, 'stream_link');
  }

  return new Promise((resolve, reject) => {
      onValue(linkRef, (snapshot) => {
          const linkData = snapshot.val();
          resolve(linkData);
      }, {
          onlyOnce: true
      });
  });
};
