import React, { useEffect, useState, useRef } from 'react';
import { Button, LinearProgress, Typography, List } from '@mui/material';
import { fetchFighterData } from './FirebaseApiCalls';

const FighterCard = ({ votes, selectedFighters, ACCENT_COLOR, handleVote, setSnapShotFighterList }) => {

    const [bouts, setBouts] = useState([]);
    const [focusedIndex, setFocusedIndex] = useState(0);
    const fighterCardDivRef = React.createRef();
    const cardRefs = useRef([]);

    const handleScroll = (direction) => {
        const newIndex = direction === 'next' ? focusedIndex + 1 : focusedIndex - 1;

        if (newIndex >= 0 && newIndex < cardRefs.current.length) {
            setFocusedIndex(newIndex);
            cardRefs.current[newIndex].scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    };

    // Function to handle scrolling up
    const scrollUp = () => {
        handleScroll('bootyCheeks');
    };

    // Function to handle scrolling down
    const scrollDown = () => {
        handleScroll('next');
    };

    function calculateFighterName(index, votes) {
        if (selectedFighters[index] > -1) {
            return bouts[index][selectedFighters[index]].name;
        } else {
            return null;
        }
    }

    useEffect(() => {
        fetchFighterData().then((data) => {
            setBouts(data);
            setSnapShotFighterList({ 0: data[0][0].name.split(" ")[1], 1: data[0][1].name.split(" ")[1] });
        }).catch((error) => {
            console.error('Error fetching fighter data:', error);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Attach the scroll event listener when the component mounts
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleWheel = (event) => {
        event.preventDefault(); // Prevent default scrolling behavior
    };

    useEffect(() => {
        const fighterCardElement = fighterCardDivRef.current;
        fighterCardElement.addEventListener('wheel', handleWheel, { passive: false });

        return () => {
            fighterCardElement.removeEventListener('wheel', handleWheel);
        };
    });

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={scrollUp} sx={{ color: 'white', fontSize: '25px' }}>
                    &uarr;
                </Button>
                <Button onClick={scrollDown} sx={{ color: 'white', fontSize: '25px' }}>
                    &darr;
                </Button>
            </div>
            <div className='fighterCard' style={{ maxHeight: '65vh', overflow: 'auto' }} ref={fighterCardDivRef} >
                <List style={{ paddingInlineStart: '0px' }}>
                    {bouts.map((bout, index) => (
                        <li key={index} ref={(ref) => (cardRefs.current[index] = ref)} style={{
                            transform: index === focusedIndex ? 'scale(1)' : 'scale(0.7)',
                            opacity: index === focusedIndex ? 1 : 0.5,
                            transition: 'transform 0.3s ease, opacity 0.3s ease',
                        }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                <Typography
                                    variant="subtitle1"
                                    align='left'
                                    style={{ color: 'white', marginTop: '5px', fontWeight: 'bold', fontSize: '10px' }}
                                >
                                    {bout[0].name}
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    align='right'
                                    style={{ color: 'white', marginTop: '5px', fontWeight: 'bold', fontSize: '10px' }}
                                >
                                    {bout[1].name}
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                <Typography
                                    variant="subtitle1"
                                    align='left'
                                    style={{ color: 'white', marginTop: '5px', fontWeight: 'bold', fontSize: '10px' }}
                                >
                                    {bout[0].record}
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    align='right'
                                    style={{ color: 'white', marginTop: '5px', fontWeight: 'bold', fontSize: '10px' }}
                                >
                                    {bout[1].record}
                                </Typography>
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-evenly',
                                    backgroundColor: 'black',
                                }}
                            >
                                {bout.map((fighter, fighterIndex) => (
                                    <img
                                        key={fighterIndex}
                                        alt='FighterImage'
                                        src={fighter.img}
                                        style={{
                                            height: '10vw',
                                            objectFit: 'cover',
                                            objectPosition: 'top',
                                            flex: 1,
                                            cursor: 'pointer',
                                            borderBottom: selectedFighters[index] === fighterIndex ? '12px solid #9e9e9e' : 'none',
                                            borderRadius: "10px"
                                        }}
                                        onClick={() => handleVote(index, fighterIndex, focusedIndex)}
                                    />
                                ))}
                            </div>
                            {calculateFighterName(index, votes) ? <div><LinearProgress
                                variant="determinate"
                                className='fade-in'
                                value={(votes[index][0] / (votes[index][0] + votes[index][1])) * 100}
                                sx={{
                                    width: '100%', marginTop: '24px', height: '15px', backgroundColor: '#FF9f9f', // Custom color 1
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: '#D20A0A',
                                    }
                                }}
                            />
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                    <Typography
                                        variant="subtitle1"
                                        style={{ color: 'white', marginTop: '5px', fontWeight: 'bold' }}
                                    >
                                        {Math.round((votes[index][0] / (votes[index][0] + votes[index][1])) * 100)}%
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        style={{ color: 'white', marginTop: '5px', fontWeight: 'bold' }}
                                    >
                                        {Math.round((votes[index][1] / (votes[index][0] + votes[index][1])) * 100)}%
                                    </Typography>
                                </div>
                            </div> : <Typography
                                variant="subtitle1"
                                className='bigtitles'
                                style={{ color: 'white', marginTop: '10px' }}
                            >
                                Select a fighter
                            </Typography>}
                            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'flex-start' }}>
                                <Button onClick={() => window.open(bout[0].details, "_blank")} sx={{ minWidth: '0px', color: 'white', marginTop: '5px', fontSize: '1vw', backgroundColor: ACCENT_COLOR }}>
                                    Details
                                </Button>
                                <Button onClick={() => window.open(bout[1].details, "_blank")} sx={{ minWidth: '0px', color: 'white', marginTop: '5px', fontSize: '1vw', backgroundColor: ACCENT_COLOR }}>
                                    Details
                                </Button>
                            </div>
                        </li>
                    ))}
                </List>
            </div>
        </div>
    );
};

export default FighterCard;